<template>
  <div class="service-form-part">
    <ServiceField :init-with-value="serviceId" hidden v-on="$listeners" />

    <MarketSegmentRadioField :choices="marketSegments" v-on="$listeners" />
  </div>
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import { solarPanelsPurchaseES } from 'chimera/solarPanels/service'
import MarketSegmentRadioField from 'chimera/all/components/form/fields/marketSegment/MarketSegmentRadioField.vue'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceField,
    MarketSegmentRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      marketSegments: [
        new Selectable('Casa', 'Casa', this.$t('field.marketSegment.consumer')),
        new Selectable(
          'Empresa',
          'Empresa',
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          'Negocio agrario',
          'Negocio agrario',
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          'Gran proyecto empresarial',
          'Gran proyecto empresarial',
          this.$t('field.marketSegment.corporateValue'),
        ),
      ],

      serviceId: solarPanelsPurchaseES.id,
    }
  },
}
</script>
