<template>
  <RoofTypeField :choices="choices" v-on="$listeners" />
</template>

<script>
import RoofTypeField from 'chimera/all/components/form/fields/roofType/RoofTypeField'
import TSelectableWithTextField from 'chimera/all/components/elements/TSelectableWithTextField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'RoofTypeFormPart',

  components: {
    RoofTypeField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$t('flatRoof'),
          this.$t('flatRoof'),
          this.$t('flatRoof'),
        ),
        new Selectable(
          this.$t('pitchedRoof'),
          this.$t('pitchedRoof'),
          this.$t('pitchedRoof'),
        ),
        new Selectable(
          this.$t('global.other'),
          this.$t('global.other'),
          this.$t('global.other'),
          {
            component: TSelectableWithTextField,
            textField: true,
          },
        ),
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "flatRoof": "Plat dak",
    "pitchedRoof": "Schuin dak"
  },
  "nl-BE": {
    "flatRoof": "Plat dak",
    "pitchedRoof": "Schuin dak"
  },
  "es-ES": {
    "flatRoof": "Tejado plano",
    "pitchedRoof": "Tejado inclinado"
  },
  "de-DE": {
    "flatRoof": "Flachdach",
    "pitchedRoof": "Schrägdach"
  },
  "fr-BE": {
    "flatRoof": "Toit plat",
    "pitchedRoof": "Toit incliné"
  },
  "fr-FR": {
    "flatRoof": "Toit plat",
    "pitchedRoof": "Toit incliné"
  },
  "it-IT": {
    "flatRoof": "Tetto piano",
    "pitchedRoof": "Tetto inclinato"
  }
}
</i18n>
